import React, { useEffect } from "react";
import Header from "./components/Header";
import { Switch, Route, useLocation } from "react-router-dom";
import SubmittedPage from "./pages/Submitted";
import {
  Box,
  Button,
  Container,
  Grid,
  MuiThemeProvider,
  Paper,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FormPageProps } from "./pages/FormPageCommon";
import { getPrintTheme } from "./GetPrintTheme";

const openImprint = () => {
  window.open("https://reha-bedarf.com/impressum");
};

const openPrivacy = () => {
  window.open("https://reha-bedarf.com/datenschutzerklaerung");
};

const downloadAgreement = () => {
  window.open("/Patienteneinverstaendniserklaerung.pdf");
};

const useStyles = makeStyles({
  agreementBtn: {
    marginLeft: 96,
  },
});

export interface FormAppProps {
  formPage: React.ComponentType<FormPageProps>;
  formNameHeader: string;
}

const FormApp = (props: FormAppProps) => {
  const classes = useStyles();
  const location = useLocation();
  const theme = useTheme();

  const { formPage: FormPage, formNameHeader } = props;

  useEffect(() => {
    document.title = formNameHeader + " | Reha Bedarf GmbH";
  }, [formNameHeader]);

  if (location.pathname === "/print") {
    return (
      <MuiThemeProvider theme={getPrintTheme(theme)}>
        <Header formName={formNameHeader} />
        <FormPage print />
      </MuiThemeProvider>
    );
  }

  return (
    <>
      <Header formName={formNameHeader} />
      <Box mt={2}>
        <Container maxWidth={"md"}>
          <Paper>
            <Box m={2}>
              <Switch>
                <Route path={"/submitted"} component={SubmittedPage} />
                <Route path={"/"} component={FormPage} />
              </Switch>
            </Box>
          </Paper>
          <Box m={2}>
            <Grid
              container
              spacing={2}
              justify={"space-between"}
              className={"hide-on-print"}
            >
              <Grid item>
                <Button onClick={openImprint}>Impressum</Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={downloadAgreement}
                  className={classes.agreementBtn}
                >
                  Patienteneinverständniserklärung
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={openPrivacy}>Datenschutzerklärung</Button>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default React.memo(FormApp);
